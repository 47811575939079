import React, { useRef, Suspense, lazy } from "react";
import styles from "../styles/screens/Homescreen.module.css";
import {app} from "../utils/firestoredb";
import { getAnalytics, logEvent } from "firebase/analytics";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NavBar from "../components/Navbar";
import Footer from "../components/Footer";
//import Customer from "../components/Customer";
import Download from "../components/Download";
import splashImage from "../assets/background/splash.png";
import { usePostHog } from 'posthog-js/react'
//import LatestBookPuja from "../components/bookpuja/LatesBookPuja";
import Metadata from "../components/metadata/MetaData";

const LatestBookPuja = lazy(() => import("../components/bookpuja/LatesBookPuja"));
const Customer = lazy(() => import("../components/Customer"));

function HomeScreen() {
  const posthog = usePostHog();
  const navigate = useNavigate();
  const bookPujaRef = useRef(null);
  const { t } = useTranslation();
  const analytics = getAnalytics(app);
  logEvent(analytics, "Visited Home Page");
  posthog?.capture('homepage');

  return (
    <div>
      <Metadata
        description="Dharmik online puja and prasad in Ayodhya"
        keywords="dharmik, dharmikapp, ayodhya, puja, prasad, online puja, online prasad, hindu puja online"
        ogTitle="Dharmik App online puja and prasad in Ayodhya"
        ogDescription="Online puja and prasad in Ayodhya via Dharmik App"
        ogImage="https://d3vqmpzcil03dt.cloudfront.net/webite-assets/dharmik_logo.png"
        ogUrl="https://dharmikapp.in"
        canonicalUrl="https://dharmikapp.in"
        twitter="@dharmikapp"
        youtube="@dharmik_hindi"
        instagram="@dharmik_app"
        author="Dharmik App"
        publisher="Dharmik"
        datePublished="2024-09-06"
       />
        <NavBar bookPujaRef={bookPujaRef} />
        <div className={styles.homepage}>
          <div className={styles.homepageImage}>
            <div className={styles.hp_content}>
              <div className={styles.hp_text_content}>
                <h1>{t("Book Puja at Mandir of your choice from home")}</h1>
                <p>
                  {t("We help you book puja and get prasad delivered to your door.")}
                </p>
                <div className={styles.buttons_group}>
                  <button
                    className={styles.white_button}
                    onClick={() => navigate("./contact-us")}
                  >
                    {t("Contact Us")}
                  </button>
                </div>
              </div>
              <img className={styles.splashImage} src={splashImage} alt="Splash"  />
            </div>
          </div>
        </div>
        <div ref={bookPujaRef}>
          <Suspense>
          <LatestBookPuja />
          </Suspense>
        </div>
      <Suspense>
      <Customer />
      </Suspense>
      <br></br>
      <br></br>
      <Download />
      <Footer />
    </div>
  );
}

export default HomeScreen;
