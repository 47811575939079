import React, { useState, useEffect } from "react";
import NavBar from "../../components/Navbar";
import { useParams } from "react-router-dom";
import styles from "../../styles/screens/Ayodhya/PujaBookingForm.module.css";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../../components/Footer";
import { useTranslation } from "react-i18next";
import {db} from "../../utils/firestoredb";
import { useNavigate } from "react-router-dom";
import {
  doc,
  serverTimestamp,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import PujaProgressLine from "../../components/PujaProgressLine";
import Metadata from "../../components/metadata/MetaData";

function PujaBookingForm() {
  const { pujaId, offeringId, orderId} = useParams();
  const [submitting, setSubmitting] = useState(false);
  const [pujaDetails, setPujaDetails] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const [name, setName] = useState([
    JSON.parse(localStorage.getItem("name"))
      ? JSON.parse(localStorage.getItem("name"))
      : "",
  ]);
  const phone = JSON.parse(localStorage.getItem("phoneNumber"));
  const [email, setEmail] = useState(
    JSON.parse(localStorage.getItem("email"))
      ? JSON.parse(localStorage.getItem("email"))
      : ""
  );
  const [house, setHouse] = useState("");
  const [locality, setLocality] = useState("");
  const [city, setCity] = useState("");
  const [gotra, setGotra] = useState("");
  const [pincode, setPincode] = useState("");
  const [date, setDate] = useState("");
  const [language, setLanguage] = useState("en");
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const isLoggedIn = JSON.parse(localStorage.getItem("phoneNumber")) ? true : false;

  useEffect(() => {
    const currentLanguage = i18n.language;
    setLanguage(currentLanguage);
  }, [i18n.language]);

  useEffect(() => {
    const fetchPujaDetails = async () => {
      const pujaDocRef = doc(db, "ayodhyapuja", pujaId);

      try {
        const pujaQuerySnapshot = await getDoc(pujaDocRef);
        if (pujaQuerySnapshot.exists()) {
            const pujaData = pujaQuerySnapshot.data();
            setPujaDetails(pujaData);
        }
      } catch (error) {
        console.error("Error getting Puja document : ",error);
      }
    };

    fetchPujaDetails();
  }, [pujaId]);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      const orderDocRef = doc(db, "orders", orderId);

      try {
        const orderSnapshot = await getDoc(orderDocRef);
        if (orderSnapshot.exists()) {
            const orderDocData = orderSnapshot.data();
            if(orderDocData[orderId]) {
                setOrderData(orderDocData[orderId]);
            }
        }
      } catch (error) {
        console.error("Error getting Puja document : ",error);
      }
    };

    fetchOrderDetails();
  }, [orderId]);

  if (!pujaDetails && !orderData) {
    return <div>Loading...</div>;
  } else {
      console.log("PUJA DETAILS : ", pujaDetails);
      console.log("ORDER DATA : ", orderData);
  }

  const onClickBook = async (e) => {
    e.preventDefault();
    if (submitting) return;

    setSubmitting(true);

    try {
        const orderRef = doc(db, "orders", orderId);
        const orderSnap = await getDoc(orderRef);

        if(orderSnap.exists()) {
            await updateDoc(orderRef, {[orderId] : {
                ...orderData,
                name: name[0],
                address : {
                    city: city,
                    house: house,
                    locality: locality,
                    pincode: pincode,
                },
                date: date,
                gotra: gotra,
                email: email,
                updated_time: serverTimestamp(),
            }});
        }
    }catch (e) {
        console.error("ERROR IN UPDATING ORDERS DSOC : ", e);
    }

    try {
        const orderRef = doc(db, "order_history", phone);
        const orderSnap = await getDoc(orderRef);

        if(orderSnap.exists()) {
            await updateDoc(orderRef, {[orderId] : {
                ...orderData,
                name: name[0],
                address : {
                    city: city,
                    house: house,
                    locality: locality,
                    pincode: pincode,
                },
                date: date,
                gotra: gotra,
                email: email,
                updated_time: serverTimestamp(),
            }});
        }
    }catch (e) {
        console.error("ERROR IN UPDATING ORDERS DSOC : ", e);
    }
    window.location.href =  window.location.origin + "/booking/results/" + pujaId + "/" + offeringId + "/" + orderId;
  };

  if (!isLoggedIn) navigate("/login/" + pujaId + "/" + offeringId);
  if (pujaDetails)
  return (
    <div className={styles.ayodhya}>
      <Metadata
        description="Dharmik online puja and prasad in Ayodhya"
        keywords="dharmik, dharmikapp, ayodhya, puja, prasad, online puja, online prasad, hindu puja online"
        ogTitle="Dharmik App online puja and prasad in Ayodhya"
        ogDescription="Online puja and prasad in Ayodhya via Dharmik App"
        ogImage="https://d3vqmpzcil03dt.cloudfront.net/webite-assets/dharmik_logo.png"
        ogUrl="https://dharmikapp.in"
        canonicalUrl="https://dharmikapp.in"
        twitter="@dharmikapp"
        youtube="@dharmik_hindi"
        instagram="@dharmik_app"
        author="Dharmik App"
        publisher="Dharmik"
        datePublished="2024-09-06"
       />
      <NavBar />
      <PujaProgressLine activeStep={3}/>
      <main className={styles.mainContent}>
          <section className={styles.leftSection}>
          <div className={styles.card_container}>
            <img
                src={pujaDetails.offerings[offeringId-1].image}
                alt="Hanuman Garhi"
                className={styles.card_image}
            />
                <h2 className={styles.card_title}>{language === "en" ? pujaDetails.offerings[offeringId-1].title.en : pujaDetails.offerings[offeringId-1].title.hi}</h2>
                <p className={styles.card_description}>
                    {language === "en" ? pujaDetails.offerings[offeringId-1].description.en : pujaDetails.offerings[offeringId-1].description.hi}
                </p>
            <div className={styles.card_footer}>
                <span className={styles.card_shulk}>{t("Puja Shulk")}</span>
                <span className={styles.card_price}>{orderData.price}</span>
            </div>
          </div>
          </section>
        <section className={styles.rightSection}>
          <div className={styles.formGroup}>
            <label htmlFor="Name">{t("Name") + " "}</label>
          </div>
          <form onSubmit={onClickBook}>
            {name.map((nameValue, index) => (
              <div className={styles.formGroup} key={index}>
                <div className={styles.inputContainer}>
                  <input
                    type="text"
                    id={`name${index + 1}`}
                    name={`name${index + 1}`}
                    value={nameValue}
                    onChange={(e) => {
                      const updatedNames = [...name];
                      updatedNames[index] = e.target.value;
                      if (!submitting) setName(updatedNames);
                    }}
                    placeholder={
                      t("Name") +
                      (index === 0 ? t(" (required)") : t(" (optional)"))
                    }
                    required={index === 0}
                  />
                  {index === 0 && name.length < 4 && (
                    <button
                      className={`${styles.addButton} ${styles.plusSign}`}
                      onClick={() => {
                        if (!submitting) setName([...name, ""]);
                      }}
                    >
                      +
                    </button>
                  )}
                  {index > 0 && (
                    <button
                      className={`${styles.addButton} ${styles.minusSign}`}
                      onClick={() => {
                        if (!submitting) {
                          const updatedNames = [...name];
                          updatedNames.splice(index, 1);
                          setName(updatedNames);
                        }
                      }}
                    >
                      -
                    </button>
                  )}
                </div>
              </div>
            ))}
            <div className={styles.formGroup}>
              <input
                type="text"
                value={gotra}
                onChange={(e) => {
                  if (!submitting) setGotra(e.target.value);
                }}
                placeholder={t("Gotra") + t(" (optional)")}
              />
            </div>
            <div className={styles.formGroup}>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={phone}
                placeholder={t("Phone Number") + t(" (required)")}
                readOnly
                required
              />
            </div>
            <div className={styles.formGroup}>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => {
                  if (!submitting) setEmail(e.target.value);
                }}
                placeholder={t("Email") + t(" (optional)")}
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="date">
                {t("Date for Pooja") + t(" (optional)")}
              </label>
            </div>
            <div className={styles.formGroup}>
              <input
                type="date"
                id="date"
                name="date"
                value={date}
                onChange={(e) => {
                  if (!submitting) setDate(e.target.value);
                }}
                min={new Date().toISOString().split("T")[0]}
              />
            </div>
            <div className={styles.formGroup}>
              <input
                type="text"
                value={house}
                onChange={(e) => {
                  if (!submitting) setHouse(e.target.value);
                }}
                placeholder={t("House Name/Number")}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <input
                type="text"
                value={locality}
                onChange={(e) => {
                  if (!submitting) setLocality(e.target.value);
                }}
                placeholder={t("Locality/Street")}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <input
                type="text"
                value={city}
                onChange={(e) => {
                  if (!submitting) setCity(e.target.value);
                }}
                placeholder={t("City/Town")}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <input
                type="tel"
                id="pincode"
                name="pincode"
                value={pincode}
                onChange={(e) => {
                  const input = e.target.value;
                  const numbersOnly = input.replace(/\D/g, "");
                  if (!submitting) setPincode(numbersOnly);
                }}
                placeholder={t("Pin Code")}
                required
              />
            </div>
              <button className={styles.bookButton}>
                {!submitting ? t("Submit") : t("Submitting...")}
              </button>
          </form>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default PujaBookingForm;
