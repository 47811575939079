import { initializeApp } from "firebase/app";
import {
  getFirestore,
  doc,
  serverTimestamp,
  updateDoc,
  getDoc,
} from "firebase/firestore";

import firebaseConfig from "../../firebase";
import logo from "../../assets/logo.png";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const RazorpayOnlinePay = async (
  pujaId,
  offeringId,
  orderId,
  pujaPrice,
  phone,
  description,
  userName
) => {
  const script = document.createElement("script");
  script.src = "https://checkout.razorpay.com/v1/checkout.js";
  script.async = true;
  console.log(userName)

  script.onload = () => {
    console.log(userName);
    const options = {
      key_id: process.env.RZP_KEY,
      order_id: orderId,
      name: "Dharmik",
      description: description,
      image: logo,
      prefill: {
        name: userName,
        email: "contact@dharmik.in",
        contact: phone,
      },
      notes: "Price = " + pujaPrice,
      callback_url: window.location.origin + "/bookingform/" + pujaId + "/" + offeringId + "/" + orderId,

      handler: async function (response) {
        // Handle successful payment here
        try {
          await  updatePaymentStatusFDB(phone, orderId, "paid", response);
          window.location.href = window.location.origin + "/bookingform/" + pujaId + "/" + offeringId + "/" + orderId;
        } catch(e) {
          console.error("ERROR IN SUCCESS HANDLER : ", e);
        }
      },
      modal: {
        ondismiss: async function () {
          // Handle the case where the user cancels the payment
          try {
            await updatePaymentStatusFDB(phone, orderId, "dismiss");
            window.location.href = window.location.origin + "/booking/results/" + pujaId + "/" + offeringId + "/" + orderId;
          }catch(e) {
            console.error("ERROR IN ON DISMISS FUNCTIONM HANDLER : ", e);
          }
        }
      }
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  document.body.appendChild(script);

  return () => {
    document.body.removeChild(script);
  };
};

async function updatePaymentStatusFDB(phone, orderId, payStatus, response=null) {
  const orderRef = doc(db, "orders", orderId)
  const orderHistoryRef = doc(db, "order_history", phone);

  try {
    const orderSnap = await getDoc(orderRef);
    const orderData = orderSnap.data();
    
    if (orderSnap.exists()) {
      await updateDoc(orderRef, {[orderId]: {
        ...orderData[orderId],
        rzp_status: payStatus,
        rzp_payment_id: response ? response.razorpay_payment_id : null,
        rzp_payment_signature: response ? response.razorpay_signature : null,
        updated_time: serverTimestamp(),
      }})
    }
  }catch (e) {
    console.error("ERROR IN FETCHING ORDER DOC FROM ORDERID : ", e);
  }

  try {
    const orderHistorySnap = await getDoc(orderHistoryRef);
    const orderHistoryData = orderHistorySnap.data();
    
    if (orderHistorySnap.exists()) {
      await updateDoc(orderHistoryRef, {[orderId]: {
        ...orderHistoryData[orderId],
        rzp_status: payStatus,
        rzp_payment_id: response ? response.razorpay_payment_id : null,
        rzp_payment_signature: response ? response.razorpay_signature : null,
        updated_time: serverTimestamp(),
      }})
    }
  }catch (e) {
    console.error("ERROR IN FETCHING ORDER HISTORY DOC FROM ORDERID : ", e);
  }
}

export default RazorpayOnlinePay;
