import React, { useState, useEffect } from "react";
import NavBar from "../../components/Navbar";
import styles from "../../styles/screens/Ayodhya/LoginPay.module.css";
import loginIcon from "../../assets/login.png";
import Footer from "../../components/Footer";
import "react-phone-number-input/style.css";
import generateReferralCode from "../../utils/Referral";
import PhoneInput from "react-phone-number-input";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";
import firebaseConfig from "../../firebase";
import { useTranslation } from "react-i18next";
import {
  getAuth,
  signInWithPhoneNumber,
  RecaptchaVerifier,
  PhoneAuthProvider,
  signInWithCredential,
} from "firebase/auth";
import { useNavigate, useParams } from "react-router-dom";
import pujaLocation from "../../assets/pujalocation.png";
import pujaDate from "../../assets/pujadate.png";
import bookedIcon from "../../assets/booked.png";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth();
auth.useDeviceLanguage();

function PujaPage() {
  const { group, id } = useParams();
  const navigate = useNavigate();
  const [pujaDetails, setPujaDetails] = useState(null);
  const [language, setLanguage] = useState("en");
  const { t, i18n } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [verificationId, setVerificationId] = useState(null);

  const setUpRecaptcha = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            console.log("Recaptcha resolved.");
          },
        }
      );
      window.recaptchaVerifier.render().catch((error) => {
        console.error("Recaptcha render error: ", error);
      });
    }
  };

  const handleSendOtp = async (e) => {
    e.preventDefault();
    if (!phoneNumber || phoneNumber.length < 10) {
      toast.error("Please full name and correct mobile number", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    toast.info(t("OTP Sent"), {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

    setUpRecaptcha();
    const phoneNumberWithCountryCode = phoneNumber;
    const appVerifier = window.recaptchaVerifier;

    try {
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        phoneNumberWithCountryCode,
        appVerifier
      );
      setVerificationId(confirmationResult.verificationId);
    } catch (error) {
      toast.error(t("Error, Please try again!"), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error("Error sending OTP: ", error.message);
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    if (!verificationId) {
      return;
    }

    toast.info(t("Please wait, sigining in"), {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

    try {
      const credential = PhoneAuthProvider.credential(verificationId, otp);
      const userCredential = await signInWithCredential(auth, credential);
      console.log("Phone number verified and user signed in", userCredential);

      localStorage.setItem("phoneNumber", JSON.stringify(phoneNumber));

      const userRef = doc(db, "users", phoneNumber);
      const userSnapshot = await getDoc(userRef);

      if (userSnapshot.exists()) {
        await updateDoc(userRef, {
          phoneNumber: phoneNumber,
        });
      } else {
        await setDoc(userRef, {
          phoneNumber: phoneNumber,
          platform: "web",
          category: "general",
          referral_code: generateReferralCode(phoneNumber),
          time: serverTimestamp(),
          last_login: serverTimestamp(),
        });
        const referralRef = doc(
          db,
          "referral_db",
          generateReferralCode(phoneNumber)
        );
        await setDoc(referralRef, {
          count: 0,
        });
      }

      navigate("/pujainfo/"+group+"/"+id);

    } catch (error) {
      toast.error(t("Error, Please try again!"), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error("Error verifying OTP: ", error.message);
    }
  };

  useEffect(() => {
    const currentLanguage = i18n.language;
    setLanguage(currentLanguage);
  }, [i18n.language]);

  useEffect(() => {
    const fetchPujaDetails = async () => {
      const pujaDocRef = doc(db, "ayodhyapuja", group);

      try {
        const pujaQuerySnapshot = await getDoc(pujaDocRef);
          if (pujaQuerySnapshot.exists()) {
            const offeringData = pujaQuerySnapshot.data()
            setPujaDetails(offeringData);
          }
      } catch (error) {
        console.error("Error getting documents:", error);
      }
    };

    fetchPujaDetails();
  }, [group, id]);

  if (!pujaDetails) {
    return <div>Loading...</div>;
  }

  const analytics = getAnalytics(app);
  logEvent(analytics, "Visited: Puja booking without login page");

  return (
    <div className={styles.ayodhya}>
      <NavBar />
      <ToastContainer />
      <div className={styles.mainArea}>
        <main className={styles.mainContent}>
          <section className={styles.section}>
            <img
              src={pujaDetails.banner_image[language]}
              alt={pujaDetails.banner_image_alt_text ?? "Dharmik App Online Puja and Prasad Ayodhya"}
              className={styles.pujaImage}
            />
            <div className={styles.pujaTitle}>
                      <span>{language === "en" ? pujaDetails.offerings[id-1].title?.en : pujaDetails.offerings[id-1].title?.hi}</span>
              </div>
            <div className={styles.card_details}>
             <span className={styles.location}>
               <img src={pujaLocation} alt="Location" className={styles.location_image} />
                {pujaDetails.location[language]}
            </span>
            {pujaDetails.date[language] === "" ? null : (
              <span className={styles.date}>
                <img src={pujaDate} alt="Date" className={styles.date_image} /> {pujaDetails.date[language]}
              </span>
            )}
          </div>
          <div className={styles.card_details}>
            <span className={styles.bookedby}>
              <img src={bookedIcon} alt="booked by icon" className={styles.booked_icon} />
              {pujaDetails.booked_by[language]}
            </span>
            <span className={styles.rating}>⭐ {pujaDetails.rating}</span>
          </div>
              <div className={styles.shulk_container}>
                    <div className={styles.pay_shulk}>
                        <span>{t("Puja Shulk")}</span>
                        <span>{pujaDetails.offerings[id-1].price}</span>
                    </div>
                </div>
          </section>
          <aside className={styles.listing}>
            <img src={loginIcon} alt="Dharmik App Online Puja and Prasad Ayodhya" className={styles.loginImage} />
            {!verificationId && (
              <form onSubmit={handleSendOtp} className={styles.authForm}>
                <h2>{t("Get started")}</h2>
                <h3>{t("Enter your Mobile no. to book your Puja")}</h3>
                <PhoneInput
                  placeholder={t("Please enter your phone number")}
                  defaultCountry="IN"
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                  required
                  className={styles.phoneInput}
                />
                <button type="submit" className={styles.submitButton}>
                  {t("Send OTP")}
                </button>
              </form>
            )}
            {verificationId && (
              <form className={styles.authForm}>
                <h2>{t("Welcome to Dharmik")}</h2>
                <label htmlFor="phoneNumber">{t("Enter OTP")}</label>
                <input
                  type="tel"
                  id="otp"
                  name="otp"
                  value={otp}
                  className={styles.authInput}
                  onChange={(e) => setOtp(e.target.value)}
                  required
                />
                <button
                  onClick={handleVerifyOtp}
                  className={styles.submitButton}
                >
                  {t("Verify OTP")}
                </button>
              </form>
            )}
            <div id="recaptcha-container"></div>
          </aside>
        </main>
      </div>
      <Footer />
    </div>
  );
}

export default PujaPage;
