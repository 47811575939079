import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NavBar from "../components/Navbar";
import styles from "../styles/screens/PujaInfo.module.css";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";
import firebaseConfig from "../firebase";
import { initializeApp } from "firebase/app";
import { useNavigate } from "react-router-dom";
import {
  getFirestore,
  doc,
  getDoc,
  // setDoc,
  // serverTimestamp,
} from "firebase/firestore";
import getOrderId from "../utils/OrderId";
//import RazorpayOnlinePay from "../components/Payment/RazorpayOnlinePay";
import CreatePujaOrdersFDB from "../components/Payment/CreatePujaOrderFDB";
import PujaProgressLine from "../components/PujaProgressLine";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);


const PujaInfo = () => {

  const { pujaId, offeringId } = useParams();
  const [language, setLanguage] = useState("en");
  const [pujaDetails, setPujaDetails] = useState(null);
  const [finalPujaPrice, setFinalPujaPrice] = useState("");
  const navigate = useNavigate();
  const [paymentOptionSelected, setPaymentOptionSelected] = useState("online")
  const { t, i18n } = useTranslation();
  const [onlineOrderId, setOnlineOrderId] = useState(null);
  const [codOrderId, setCodOrderId] = useState(null);

  const phone = JSON.parse(localStorage.getItem("phoneNumber"));
  const isLoggedIn = JSON.parse(localStorage.getItem("phoneNumber")) ? true : false;


  useEffect(() => {
    const currentLanguage = i18n.language;
    setLanguage(currentLanguage);
  }, [i18n.language]);

  useEffect(() => {
    let pujaDocRef;
    const fetchPujaDetails = async () => {
      pujaDocRef = doc(db, "ayodhyapuja", pujaId);
      const pujaDocSnap = await getDoc(pujaDocRef);

      if (pujaDocSnap.exists()) {
        const pujaData = pujaDocSnap.data();
        setPujaDetails(pujaData);
        setFinalPujaPrice(pujaData.offerings[offeringId-1].price);

      } else {
        console.log("Error Fetching Puja Details for Puja: ", pujaId);
      }
    };

    fetchPujaDetails();
  }, [pujaId, offeringId]);

  useEffect (() => {
    const getPujaOrderId = async () => {
      if(pujaDetails) {
        const orderId = await getOrderId(pujaDetails.offerings[offeringId-1].price);
      if(orderId) {
        setOnlineOrderId(orderId);
      }

      if(pujaDetails.offerings[offeringId-1].codavailable) {
        const codPujaOrderId = await getOrderId(`${+pujaDetails.offerings[offeringId-1].price + +pujaDetails.offerings[offeringId-1].codcharge}`);
        setCodOrderId(codPujaOrderId);
      }
      
      }
    }
    getPujaOrderId();
  }, [offeringId, pujaDetails]);


  // navigate to Login page first for automated used details usage
  if(!isLoggedIn) navigate("/login/" + pujaId + "/" + offeringId);

  if (!pujaDetails && !onlineOrderId) {
    return <div>Loading...</div>;
  } else {
    console.log("ONLINE ORDER ID : ", onlineOrderId);
    console.log("COD ORDER ID : ", codOrderId);
  }

  const onClickBookPuja = () => {

    if(paymentOptionSelected === "online") {
      console.log("ONLINE ORDER ID: ", onlineOrderId);
      if (onlineOrderId) {
        CreatePujaOrdersFDB(phone, pujaId, offeringId, onlineOrderId, finalPujaPrice,  pujaDetails, paymentOptionSelected);
      } else {
        console.log("NO ORDER ID FOR ONLINE TRANSACTION");
      }
    }else if(paymentOptionSelected === "cod") {
      console.log("COD ORDER ID : ", codOrderId);
      if(codOrderId) {
        CreatePujaOrdersFDB(phone, pujaId, offeringId, codOrderId, finalPujaPrice,  pujaDetails, paymentOptionSelected);
      }else {
        console.log("NO ORDER ID FOR COD BOOKING");
      }
    }
    }

  const handlePaymentOptionChange = (event) => {
    const paymentOptionSelected = event.target.value;
  
    if (paymentOptionSelected === "online") {
      setPaymentOptionSelected("online");
      setFinalPujaPrice(pujaDetails.offerings[offeringId-1].price);
    } else if (paymentOptionSelected === "cod") {
      setPaymentOptionSelected("cod");
      setFinalPujaPrice(`${+pujaDetails.offerings[offeringId-1].price + +pujaDetails.offerings[offeringId-1].codcharge}`)
    }
  };

  
    return (
        <div>
        <NavBar />
        <PujaProgressLine activeStep={2}/>
        <div className={styles.result}>
          <div className={styles.cardContainer}>
            <div className={styles.card}>
              <section className={styles.section}>
                <div className={styles.puja_image_container}>
                <img
                  src={pujaDetails.banner_image[language]}
                  alt="Puja"
                  className={styles.pujaImage}
                />
                </div>
              </section>
              <aside className={styles.aside}>
                <div className={styles.title_container}>
                  <h3 className={styles.pujaTitle}>
                    {language === "en"
                      ? pujaDetails.title?.en
                      : pujaDetails.title?.hi}
                  </h3>
                </div>
                <section className={styles.features}>
                  <div className={styles.puja_description}>
                      <span>{language === "en" ? pujaDetails.offerings[offeringId-1].description?.en : pujaDetails.offerings[offeringId-1].description?.hi}</span>
                    </div>
                </section>
                <div className={styles.shulk_container}>
                    <div className={styles.pay_shulk}>
                        <span>{t("Puja Shulk")}</span>
                        <span>{pujaDetails.offerings[offeringId-1].price}</span>
                    </div>
                    {(paymentOptionSelected === "cod") ? (
                      <div className={styles.pay_shulk}>
                        <span>{t("COD Shulk")}</span>
                        <span>{pujaDetails.offerings[offeringId-1].codcharge}</span>
                      </div>
                    ) : null}
                    {(pujaDetails.offerings[offeringId-1].codavailable && paymentOptionSelected === "cod") ? (
                      <div className={styles.total_shulk}>
                        <span>{t("Total Shulk")}</span>
                        <span>{finalPujaPrice}</span>
                      </div>
                    ) : null
                  }
                    <div className={styles.additional_details}>
                        {t("bookingInfoDetails")}
                    </div>
                </div>
                <div className="radioGroup">
                    <div className="radioLabel">
                        <input
                            type="radio"
                            id="online"
                            name="online"
                            value="online"
                            checked={paymentOptionSelected === "online"}
                            onChange={handlePaymentOptionChange}
                        />
                        <label htmlFor="onlinepayment">{t("Online Payment")}</label>
                    </div>
                    {(pujaDetails.offerings[offeringId-1].codavailable) ? (
                      <div className="radioLabel">
                      <input
                          type="radio"
                          id="cod"
                          name="cod"
                          value="cod"
                          checked={paymentOptionSelected === "cod"}
                          onChange={handlePaymentOptionChange}
                      />
                      <label htmlFor="withPrasad">{t("COD Payment")}</label>
                  </div>
                    ) : null}
                </div>
                <div className={styles.book_button_container}>
                    <button className={styles.book_button} onClick={onClickBookPuja}>{t("Pay Shulk")}</button>
                </div>
              </aside>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );

};

export default PujaInfo;